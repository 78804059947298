import React, { useEffect } from 'react';
import '../styles/style.css';
import Layout from '../components/Layout';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  useEffect(() => {
        $("#webDev").animate({left: '-0px'}, 2000);
        $("#myName").animate({right: '-0px'}, 2000);
  }, []);

  return (
    <>
      <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Peter Adkins Portfolio</title>
            <meta name="description" content="This is the personal portfolio site for Peter Adkins" />
            <meta name="theme-color" content="#d0a6ff" />
        </Helmet>

        <React.Fragment>
          <div className="pAdkins">
              <h1 id="myName"><span className="cust">P</span>eter <span className="cust">A</span>dkins</h1>
              <h3 id="webDev">Front-end Developer</h3>
            </div>
            <br />
            <div className="introText">
            <div className="row">
                <div className="col-md-6"><a className="custLink" href="mailto:adkins_peter@hotmail.com"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg> adkins_peter@hotmail.com</a></div>

                <div className="col-md-6"><a className="custLink" href="tel:07926619158">07926619158<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                    </svg></a></div>
              </div>
            </div>
          </React.Fragment>
      </Layout>
    </>
  )
}

export default IndexPage
